<template>
  <!-- eslint-disable  -->
  <div>
    <v-card flat class="w100p"></v-card>

    <div class="d-sm-flex mb-3 text-Heading6 Medium d-none">
      {{ $store.getters['app/getRouteTitle'] }}
    </div>

    <FilterBox
      :open.sync="isFilterOpen"
      v-model="filter"
      :loading="isLoading"
      :options="filterOptions"
      @search="fetchData"
    />

    <v-card
      outlined
      :color="$vuetify.breakpoint.smAndDown ? 'transparent' : null"
    >
      <v-card-text class="d-flex align-center pb-0 pb-sm-4">
        <v-spacer></v-spacer>
        <RefreshBtn :fetchData="fetchData" :loading="isLoading" />

        <SizeBox width="14" />
        <ExportBtn
          :items="result.list"
          :headers="headers"
          :file-name="`${
            $store.getters['app/getRouteTitle']
          } ${$moment().format('YYYY-MM-DD_H:mm:ss')}`"
          sheet-name="sheetName"
        />
      </v-card-text>
      <DataTable
        :list="result.list"
        :fields="headers"
        :total="result.total"
        :loading="isLoading"
        :replace="['created_at|formatTime']"
        :labels="labels"
        @get-list="fetchData"
      ></DataTable>
    </v-card>
    <FunctionFab
      @info="isInfoOpen = true"
      @refresh="fetchData()"
      @open-filter="isFilterOpen = true"
      :no-filter="!filterOptions.length"
      :no-info="!Object.entries(result.header_info).length"
    />
  </div>
</template>

<script>
import { getSmsList } from '@/api/sms';

export default {
  async created() {
    this.fetchData();
  },

  data() {
    return {
      ...this.$clone(this.$commonSchema),
      labels: {
        type: [
          { text: '收款', value: 'RECEIVE', color: 'Success500' },
          { text: '出款', value: 'SEND', color: 'Alert500' }
        ]
      },
      headers: [
        {
          text: '訊息編號',
          value: 'id',
          align: 'center',
          width: '80px'
        },
        {
          text: '上傳時間',
          value: 'created_at',
          dataFormat: this.$root.$options.filters.formatTime,
          align: 'center',
          width: '150px'
        },
        {
          text: '訂單編號',
          value: 'collect_order.platform_order_no',
          align: 'center',
          width: '190px'
        },
        {
          text: '類型',
          value: 'type',
          align: 'center',
          width: '80px'
        },
        {
          text: '手機號碼',
          value: 'mobile',
          align: 'center',
          width: '80px'
        },
        {
          text: '銀行名稱',
          value: 'bank_name',
          align: 'center',
          width: '100px'
        },
        {
          text: '銀行帳號尾碼',
          value: 'bank_account_last_digits',
          align: 'center',
          width: '100px'
        },
        {
          text: '金額',
          value: 'amount',
          align: 'center'
        },
        {
          text: '餘額',
          value: 'balance',
          align: 'center'
        },
        {
          text: '銀行簡碼',
          value: 'sms_short_no',
          align: 'center'
        },
        {
          text: '帳戶名稱',
          value: 'collect_order.card.name_1',
          align: 'center'
        },
        {
          text: '短信內容',
          value: 'content',
          align: 'center',
          width: '250px'
        }
      ],
      filterOptions: [
        {
          label: '上傳時間',
          type: 'timeSet',
          name: 'filter.created_at',
          range: true,
          withTime: true
        },
        {
          label: '銀行名稱',
          type: 'text',
          name: 'filter.bank_name'
        },
        {
          label: '手機號碼',
          type: 'text',
          name: 'filter.mobile'
        },
        {
          label: '關鍵字',
          type: 'text',
          name: 'filter.content'
        }
      ]
    };
  },

  computed: {},

  methods: {
    async fetchData(condition = this.condition) {
      this.isLoading = true;
      this.condition = condition;

      const SmsList = await getSmsList({
        ...condition,
        ...this.filter
      });

      if (!SmsList.error) {
        this.result.total = SmsList.total;
        this.result.page = SmsList.page;
        this.$set(
          this.result,
          'list',
          SmsList.items.map((item) => ({
            ...item
          }))
        );
      }

      this.isLoading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
